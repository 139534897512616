export default {
	'button-window': () => import('./button-window'),
	'choose-media': () => import('./choose-media'),
	'flyingobject': () => import('./flyingobject'),
	'iconpalette': () => import('./iconpalette'),
	'imagegallery': () => import('./imagegallery'),
	'imageoptions': () => import('./imageoptions'),
	'link': () => import('./link'),
	'mediaoptions': () => import('./mediaoptions'),
	'pageoptions': () => import('./pageoptions'),
	'textalign': () => import('./textalign'),
	'commerce': () => import('./commerce'),
	'audioplayer': () => import('./audioplayer'),
	'columns': () => import('./columns'),
	'digital-clock': () => import('./digital-clock'),
	'marquee': () => import('./marquee'),
	'textcolor': () => import('./textcolor'),
	'textoptions': () => import('./textoptions')
}